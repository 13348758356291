<template>
  <DgModal
    class="dg-nps-modal"
    size="md"
    :options="{
      title: $t('nps.feedback.title'),
    }"
    :show="true"
    @close="cancelNPS"
  >
    <div class="dg-nps-box">
      <p class="dg-nps-box__desc" v-html="$t('nps.feedback.text')" />
      <div class="dg-nps-box__vote">
        <div
          :class="['dg-nps-box__point', { 'dg-nps-box__point--active': npsScore === vote }]"
          :key="'point-' + vote"
          v-for="(n, vote) in maxNPSScore"
          @click="changeNPSScore(vote)"
        >
          {{ vote }}
        </div>
      </div>
      <div class="dg-nps-box__poll-guide">
        <p>{{ $t("nps.feedback.not_likely") }}</p>
        <p>{{ $t("nps.feedback.extremely_likely") }}</p>
      </div>

      <dg-input
        :label="$t('nps.feedback.comment.label')"
        :attrs="{
          placeholder: $t('nps.feedback.comment.placeholder'),
          value: comment,
          maxlength: maxCommentLength,
          rows: 3,
        }"
        :help="$t('nps.feedback.comment.char_count', { char_count: maxCommentLength - comment.length })"
        inputType="large"
        @input="ev => (comment = ev.target.value)"
      />

      <hr class="dg-nps-box__divider" />

      <div class="dg-nps-box__btn-group">
        <DgButton @click="cancelNPS" class="dg-nps-box__cancel-btn" :onlyText="true" size="small">
          {{ $t("nps.feedback.cancel") }}
        </DgButton>
        <DgButton :loading="loading" :disabled="npsScore === null" @click="submitNPS" size="small">
          {{ $t("nps.feedback.submit_feedback") }}
        </DgButton>
      </div>
      <p class="dg-nps-box__helper-text">
        {{ $t("nps.feedback.privacy_info.part1") }}
        <a class="dg-nps-box__priv-policy" target="_blank" :href="$t('bottom_bar.privacy_policy.link')">{{
          $t("bottom_bar.privacy_policy.label")
        }}</a>
        {{ $t("nps.feedback.privacy_info.part2") }}
      </p>
    </div>
  </DgModal>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxNPSScore: 11,
      npsScore: null,
      comment: "",
      maxCommentLength: 420,
    };
  },
  methods: {
    changeNPSScore(npsScore) {
      this.npsScore = npsScore;
    },
    cancelNPS() {
      this.$emit("onSubmit");
    },
    submitNPS() {
      this.$emit("onSubmit", this.npsScore, this.comment);
    },
  },
};
</script>
<style lang="scss">
.dg-nps-modal > .dg-ds-modal__container {
  min-height: unset;
}
.dg-nps-box {
  &__desc {
    color: #003349;
  }

  &__btn-group {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__cancel-btn {
    margin-right: 10px;
  }

  &__helper-text {
    margin-top: 24px;
    color: #7e93a7;
  }

  &__priv-policy {
    color: #7e93a7;
    text-decoration: underline;

    &:hover,
    &:active {
      color: #7e93a7;
    }
  }

  &__divider {
    margin-top: 24px;
    background: #c9d2d7;
  }

  &__vote {
    display: flex;
    justify-content: space-between;
  }

  &__point {
    text-align: center;
    cursor: pointer;
    background: #7e93a7;
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 2.2;

    &--active {
      background: #cb333b;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__poll-guide {
    display: flex;
    justify-content: space-between;
    color: #7e93a7;
    font-size: 12px;
    cursor: default;
    margin-top: 6px;
  }
}
</style>
