<template>
  <Popup v-if="showNPSPopup" :loading="submittingNPSScore" @onSubmit="handleSubmit" />
  <Success v-else-if="showNPSSuccess" :type="successType" @onClose="closeSuccessPopup" />
</template>

<script>
import { mapGetters } from "vuex";

import { Popup, Success } from "./";

export default {
  name: "CaptureNPS",
  components: { Popup, Success },
  data() {
    return {
      showNPSPopup: false,
      submittingNPSScore: false,
      showNPSSuccess: false,
      successType: "",
    };
  },
  computed: {
    ...mapGetters(["companyData"]),
  },
  created() {
    this.showNPSPopup = this.companyData.show_nps;
  },
  methods: {
    async handleSubmit(npsScore = null, userComment) {
      this.submittingNPSScore = true;
      let submitData = {
        customer_token: this.companyData.token,
      };
      npsScore && (submitData["value"] = npsScore + "");
      userComment && (submitData["comment"] = userComment);
      try {
        await this.$http.post(`/api1/v2/net_promoter_scores`, submitData);
      } catch (error) {
        this.apiCatch(error);
      } finally {
        this.showNPSPopup = false;
        this.submittingNPSScore = false;
        npsScore !== null && this.handleSuccess(npsScore);
      }
    },
    handleSuccess(npsScore) {
      if (npsScore > 7) {
        // happy customers
        this.successType = "happy";
      } else if (npsScore > 2) {
        // normal customers
        this.successType = "meh";
      } else {
        // unhappy customers
        this.successType = "unhappy";
      }
      this.showNPSSuccess = true;
    },
    closeSuccessPopup() {
      this.showNPSSuccess = false;
      this.successType = "";
    },
  },
};
</script>
