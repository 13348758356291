<template>
  <DgModal
    class="dg-nps-success"
    size="md"
    :options="{
      title: $t('nps.success.title'),
    }"
    :show="true"
    @close="$emit('onClose')"
  >
    <img class="dg-nps-success__icon" src="../../images/nps-success-check.svg" alt="" />
    <div class="dg-nps-success__msg-container" v-if="type === 'happy'">
      <p class="dg-nps-success__msg dg-nps-success__msg--happy">
        <img src="../../images/nps-check-happy.svg" alt="" />{{ $t("nps.success.happy.message") }}
      </p>
      <p class="dg-nps-success__msg">
        {{ $t("nps.success.happy.more_info.part1") }}
        <DgLink href="https://www.capterra.com/p/219584/DataGuard/">Capterra</DgLink
        >{{ $t("nps.success.happy.more_info.part2") }}
      </p>
    </div>
    <div class="dg-nps-success__msg-container" v-if="type === 'meh'">
      <p class="dg-nps-success__msg">
        {{ $t("nps.success.normal") }}
      </p>
    </div>
    <div class="dg-nps-success__msg-container" v-if="type === 'unhappy'">
      <p class="dg-nps-success__msg dg-nps-success__msg--unhappy">
        <img src="../../images/nps-info-unhappy.svg" alt="" />{{ $t("nps.success.unhappy.message") }}
      </p>
      <p class="dg-nps-success__msg">
        {{ $t("nps.success.unhappy.more_info") }}
      </p>
      <div class="dg-nps-success__help-section">
        <div class="dg-nps-success__csm-image">
          <img :src="helpImage" :alt="helpImageAlt" @error="onImageError" />
        </div>
        <div class="dg-nps-success__help-info">
          <h3 class="dg-nps-success__help-title">{{ $t("nps.success.unhappy.help_card.title") }}</h3>
          <p>{{ $t("nps.success.unhappy.help_card.info") }}</p>
          <a class="dg-nps-success__help-link" :href="bookingLink" target="_blank">
            <DgButton>{{ $t("nps.success.unhappy.help_card.book_link_title") }}</DgButton>
          </a>
        </div>
      </div>
    </div>
  </DgModal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: "meh",
      validator: value => ["happy", "meh", "unhappy"].includes(value),
    },
  },
  computed: {
    ...mapGetters(["companyData", "featureLinkKey"]),
    helpImage() {
      const { customer_success_manager, main_contact_dpo } = this.companyData;
      return customer_success_manager?.image?.url || `data:image/png;base64, ${main_contact_dpo.image}`;
    },
    helpImageAlt() {
      const { customer_success_manager } = this.companyData;
      if (customer_success_manager.image) return this.$t("nps.success.unhappy.help_card.csm_alt_text");
      return this.$t("nps.success.unhappy.help_card.main_dpo_alt_text");
    },
    bookingLink() {
      const externalLinkObj = this.featureLinkKey("NPS", "booking_nps");
      if (externalLinkObj) {
        return this.$route.params.lang.includes("en") ? externalLinkObj.link_en : externalLinkObj.link_de;
      }
      return "#";
    },
  },
  methods: {
    onImageError(e) {
      e.target.src = "/img/Guardette.png";
    },
  },
};
</script>

<style lang="scss">
.dg-nps-success {
  & > .dg-ds-modal__container {
    min-height: unset;
  }
  & > .dg-ds-modal__container.md {
    max-width: 600px;
  }

  &__icon {
    display: block;
    margin: 0 auto;
    margin-top: 24px;
  }

  &__msg-container {
    margin-top: 32px;
  }

  &__msg {
    &--happy {
      color: #5bc88d;
    }

    &--unhappy {
      color: #7e93a7;
    }

    img {
      margin-right: 9px;
    }

    .dg-link {
      text-decoration: underline;
    }
  }

  &__help-section {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  &__help-info {
    flex: 1;
    margin-left: 36px;

    & .dg-ds-button.primary {
      &,
      &:focus {
        background-color: #7e93a7;
      }

      &:hover {
        box-shadow: unset;
      }
    }
  }

  &__help-title {
    text-transform: uppercase;
  }

  &__help-link {
    display: inline-block;

    &:active,
    &:hover {
      text-decoration: none;
    }
  }

  &__csm-image {
    width: 160px;

    img {
      width: 100%;
    }
  }
}
</style>
