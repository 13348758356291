<template>
  <main class="dg-main p-0">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </main>
</template>

<script>
export default {
  name: "Layout",
  data() {
    return {
      errorParams: {
        imageURL: "https://via.placeholder.com/100",
        type: "info",
        title: "Info",
        content: "Error Loading data, please try again.",
        closeTimeout: 10000,
      },
    };
  },
  created() {
    let status = this.$route.query.status;
    if (status && status !== "all") {
      let items = this.navMenu.sidebarNavigation.filter(el => {
        if (el.title.toUpperCase().includes(status.toUpperCase())) {
          el.state = "active";
          return el;
        } else {
          el.state = "";
          return el;
        }
      });
      this.navMenu.sidebarNavigation = [...items];
    }
    if (this.$route.name === "DSRSettings") {
      let items = this.navMenu.sidebarNavigation.filter(el => {
        if (el.link.name.toUpperCase() === this.$route.name.toUpperCase()) {
          el.state = "active";
          return el;
        } else {
          el.state = "";
          return el;
        }
      });
      this.navMenu.sidebarNavigation = [...items];
    }
  },
  methods: {
    collapseMenu() {
      this.sidebarMenuVisibility = !this.sidebarMenuVisibility;
    },
    menuItemClick(e) {
      let item = e.target;
      let items = this.navMenu.sidebarNavigation.filter(el => {
        if (el.title === item.textContent.trim()) {
          el.state = "active";
          return el;
        } else {
          el.state = "";
          return el;
        }
      });
      this.navMenu.sidebarNavigation = [...items];
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-main-content {
  flex-direction: column;
}
</style>
