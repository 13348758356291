var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DgModal',{staticClass:"dg-nps-modal",attrs:{"size":"md","options":{
    title: _vm.$t('nps.feedback.title'),
  },"show":true},on:{"close":_vm.cancelNPS}},[_c('div',{staticClass:"dg-nps-box"},[_c('p',{staticClass:"dg-nps-box__desc",domProps:{"innerHTML":_vm._s(_vm.$t('nps.feedback.text'))}}),_c('div',{staticClass:"dg-nps-box__vote"},_vm._l((_vm.maxNPSScore),function(n,vote){return _c('div',{key:'point-' + vote,class:['dg-nps-box__point', { 'dg-nps-box__point--active': _vm.npsScore === vote }],on:{"click":function($event){return _vm.changeNPSScore(vote)}}},[_vm._v(" "+_vm._s(vote)+" ")])}),0),_c('div',{staticClass:"dg-nps-box__poll-guide"},[_c('p',[_vm._v(_vm._s(_vm.$t("nps.feedback.not_likely")))]),_c('p',[_vm._v(_vm._s(_vm.$t("nps.feedback.extremely_likely")))])]),_c('dg-input',{attrs:{"label":_vm.$t('nps.feedback.comment.label'),"attrs":{
        placeholder: _vm.$t('nps.feedback.comment.placeholder'),
        value: _vm.comment,
        maxlength: _vm.maxCommentLength,
        rows: 3,
      },"help":_vm.$t('nps.feedback.comment.char_count', { char_count: _vm.maxCommentLength - _vm.comment.length }),"inputType":"large"},on:{"input":ev => (_vm.comment = ev.target.value)}}),_c('hr',{staticClass:"dg-nps-box__divider"}),_c('div',{staticClass:"dg-nps-box__btn-group"},[_c('DgButton',{staticClass:"dg-nps-box__cancel-btn",attrs:{"onlyText":true,"size":"small"},on:{"click":_vm.cancelNPS}},[_vm._v(" "+_vm._s(_vm.$t("nps.feedback.cancel"))+" ")]),_c('DgButton',{attrs:{"loading":_vm.loading,"disabled":_vm.npsScore === null,"size":"small"},on:{"click":_vm.submitNPS}},[_vm._v(" "+_vm._s(_vm.$t("nps.feedback.submit_feedback"))+" ")])],1),_c('p',{staticClass:"dg-nps-box__helper-text"},[_vm._v(" "+_vm._s(_vm.$t("nps.feedback.privacy_info.part1"))+" "),_c('a',{staticClass:"dg-nps-box__priv-policy",attrs:{"target":"_blank","href":_vm.$t('bottom_bar.privacy_policy.link')}},[_vm._v(_vm._s(_vm.$t("bottom_bar.privacy_policy.label")))]),_vm._v(" "+_vm._s(_vm.$t("nps.feedback.privacy_info.part2"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }