<template>
  <div>
    <template v-if="dashboardBlocks && !error && dashboardBlocks.available_modules && !isLoading">
      <transition
        name="slide-fade"
        appear
        :key="index"
        v-for="(dashboardBlock, index) in dashboardBlocks.available_modules"
      >
        <div
          :class="[
            'section-wrapper',
            { 'section-wrapper--no-section-margin section-wrapper--intro': dashboardBlock === 'welcome' },
          ]"
          :data-testId="`dg-${dashboardBlock}-block`"
        >
          <DgSection
            :title="dashboardBlocks.modules[dashboardBlock].defaults.title"
            :description="dashboardBlocks.modules[dashboardBlock].defaults.desc"
            :flexHeight="dashboardBlock === 'welcome'"
            :options="caculateOptions(dashboardBlocks.modules[dashboardBlock])"
            :class="[{ 'dg-section--bg-light-blue': shouldHaveBGLightBlue(dashboardBlock) }]"
          >
            <slot v-if="dashboardBlocks.modules[dashboardBlock]">
              <renderEngine :engine="dashboardBlock" :engineData="dashboardBlocks.modules[dashboardBlock]" />
            </slot>
          </DgSection>
        </div>
      </transition>
    </template>
    <template v-else-if="error && !isLoading">
      <ErrorComponent :status="error.status" @onClick="fetchDashboardData" btnTitle="Try again" />
    </template>
    <template v-else>
      <div :key="i" v-for="(v, i) in [1, 2, 3]" class="shimmer-wrapper">
        <div class="shine box"></div>
        <div class="shim-container">
          <div class="shine lines"></div>
          <div class="shine lines"></div>
          <div class="shine lines"></div>
        </div>
      </div>
    </template>
    <CaptureNPS />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Structural } from "@/dgui-customer-components/";
import ErrorComponent from "@/engines/common/components/ErrorComponent";

import { CaptureNPS } from "../components/NPS";

export default {
  name: "Dashboard",
  components: {
    DgSection: Structural.DgSection,
    CaptureNPS,
    renderEngine: () => import("../components/renderEngine.vue"),
    ErrorComponent,
  },
  computed: {
    shouldHaveBGLightBlue() {
      return sectionKey => {
        return ["welcome", "knowledge_center"].includes(sectionKey);
      };
    },
    ...mapGetters(["companyData"]),
  },
  watch: {
    companyData(n, o) {
      this.fetchDashboardData();
    },
  },
  data() {
    return {
      isLoading: Boolean(this.companyData),
      dashboardBlocks: null,
      error: null,
    };
  },
  created() {
    if (this.companyData) {
      this.fetchDashboardData();
    }
  },
  methods: {
    scrollingBehaviour() {
      this.$nextTick(() => {
        const key = window.location.hash.substring(1);
        if (this.dashboardBlocks.available_modules.includes(key)) {
          document
            .querySelector(`[data-testid=dg-${key}-block]`)
            .scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    },
    fetchDashboardData() {
      if (this.isLoading) {
        return;
      }

      if (this.error !== null) {
        this.error = null;
      }

      this.isLoading = true;

      this.$http
        .get(`/api1/v2/dashboard?customer_token=${this.companyData.token}`)
        .then(response => {
          this.isLoading = false;
          this.dashboardBlocks = response.data.data;
          this.scrollingBehaviour();
        })
        .catch(error => {
          this.isLoading = false;
          this.error = {
            status: error.status,
          };
          this.apiCatch(error);
        });
    },
    getLinkInfo(key, url) {
      const trackSections = ["academy", "ppg"];
      if (trackSections.includes(key)) {
        return {
          link: url,
          internal: false,
        };
      }
      if (url && url.includes("http")) {
        const keyMappings = {
          data_subject_requests: "dsr",
          documentation: "dd",
          news_section: "news",
          whistleblowing: "dg_suite/details/3",
          dataguard_suite: "dg_suite",
          todos: "todos",
          ppm: "ppm",
        };
        const { lang, id } = this.$route.params;
        const homeRoute = `/${lang}/c/${id}/`;
        return {
          link: keyMappings[key] ? `${homeRoute}${keyMappings[key]}` : url,
          internal: Boolean(keyMappings[key]),
        };
      } else {
        return {
          link: `/redirect${url}`,
        };
      }
    },
    caculateOptions(block) {
      const { defaults: { key, color, image, image_rounded, link, help } = {} } = block;
      let blockOptions = {
        colorTheme: color && color.includes("#") ? color.substring(1) : color,
      };

      if (image) {
        blockOptions.imageURL = image;
      }

      if (image_rounded) {
        blockOptions.image_rounded = image_rounded;
      }

      if (link?.href) {
        blockOptions.navLink = {
          title: link.label,
          target: link.target,
          ...this.getLinkInfo(key, link.href),
        };
      }

      if (help?.href) {
        blockOptions.helpLink = {
          title: help.label,
          target: help.target,
          ...this.getLinkInfo(key, help.href),
        };
      }

      if (key === "academy") {
        blockOptions.classes = "no-section-content-padding";
      }
      return blockOptions;
    },
  },
};
</script>
<style lang="scss">
.not-found {
  color: rgba(0, 0, 0, 0.54);
  font-style: italic;
}

.shimmer-wrapper {
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;

  .shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 300px;
    display: inline-block;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  .box {
    height: 300px;
    width: 419px;
  }

  .shim-container {
    display: inline-flex;
    flex-direction: column;
    margin-left: 25px;
    margin-top: 15px;
    vertical-align: top;
  }

  .lines {
    height: 10px;
    margin-top: 10px;
    width: 200px;
  }

  .photo {
    display: block !important;
    width: 325px;
    height: 100px;
    margin-top: 15px;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.section-wrapper {
  display: flex;
  width: 100%;

  .dg-section {
    width: 100%;

    &--bg-light-blue {
      background: $lightblue;

      .dg-section-content,
      .dg-intro-links,
      .dg-section__nav-link {
        background: $lightblue;
      }
    }
  }

  &--no-section-margin {
    margin-bottom: 20px;

    .dg-section {
      margin-bottom: 0;
    }
  }

  &--intro {
    .dg-section {
      box-shadow: none;

      .dg-section-content_box {
        padding: 0;
      }

      .mydg-content__wrapper {
        background-color: #f1f1f1;
      }

      .mydg-left-content-wrapper {
        padding: 32px 16px;
        background-color: white;
      }
    }
  }
}
</style>
